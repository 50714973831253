import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo";
import Header from "../sections/header/Header";
import Button from "../ui/button/Button";
import { JobListingCard } from "../ui/job-listing-card/JobListingCard";

export const JoinTheTeam = ({ data }) => {
  const joinTheTeam = sections[4];

  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | Join The Leading Game Art Team In LATAM </title>
        <meta name="description" content="Kraken Creative Studios | Join The Leading Game Art Team In LATAM " />
      </Helmet>
      <Header section={joinTheTeam} />
      <div className="px-4 py-5 sm:px-56 sm:pt-16">
        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{joinTheTeam.content.submit.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{joinTheTeam.content.submit.text}</p>
        </div>

        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{joinTheTeam.content.openPositions.title}</h3>
        </div>

        {data ? <OpenPositions positions={data} /> : <NoPositions />}

      </div>
    </>
  )
}


const OpenPositions = ({ positions }) => {
  return (
    <div>
      <div className="grid sm:grid-cols-4 gap-5">
        {positions.map(item => {
          const position = item.fields;
          return (
            <JobListingCard title={position.title} description={position.description} startDate={position.startDate} jobType={position.jobType} location={position.location} payment={position.payment} jobUrl={position.jobUrl} />
          )
        })}
      </div>
      <Button text={'See More'} variant={'bg-krakenpurple text-white font-semibold mt-12 border border-transparent hover:border-white'} container={'text-center'} />
    </div>
  )
}

const NoPositions = () => {
  return (
    <p className="text-simplegray text-4xl text-center">We have no open positions at the moment!</p>
  )
}
