import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import { Route, Routes } from 'react-router-dom'
import useSWR from 'swr'
import { About } from './components/pages/About'
import { Blog } from './components/pages/Blog'
import { GoalOriented } from './components/pages/GoalOriented'
import { Homepage } from './components/pages/Homepage'
import { HourlyTerms } from './components/pages/HourlyTerms'
import { HowWeWork } from './components/pages/HowWeWork'
import { JoinTheTeam } from './components/pages/JoinTheTeam'
import { OurServices } from './components/pages/OurServices'
import { PrivacyPolicy } from './components/pages/PrivacyPolicy'
import { TermsOfUse } from './components/pages/TermsOfUse'
import { Footer } from './components/sections/footer/Footer'
import Navbar from './components/sections/navbar/navbar'
import ScrollToTop from './hooks/scrollToTop'

// New kraken USE
const client = createClient({
  space: 'q3tlofzau84g',
  accessToken: 'GfALO9R5WfVPBeJllnh1vmy6TvWJ1RSieiflEZffw4A'
})

const fetcher = async () => {
  const blogPosts = await client.getEntries({ content_type: 'blogPost' })
  const teamMembers = await client.getEntries({ content_type: 'teamMember' })
  const openPositions = await client.getEntries({ content_type: 'openPositions' })

  const posts = blogPosts.items;
  const members = teamMembers.items;
  const positions = openPositions.items;


  return { members, positions, posts };
}

function App() {
  const { data, error } = useSWR('contentful', fetcher)

  if (error) {
    console.log(error)
    return <div>Failed to load</div>
  }
  if (!data) return <div className='flex items-center justify-center bg-darkgray w-full h-screen'><Spinner size="large" /></div>

  const { posts, members, positions } = data;

  return (
    <main className='bg-darkgray'>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='ourteam' element={<About data={members} />} />
        <Route path='ourservices' element={<OurServices />} />
        <Route path='workflow' element={<HowWeWork />} />
        <Route path='jobs' element={<JoinTheTeam data={positions} />} />
        <Route path='blog' element={<Blog data={posts}/>} />
        <Route path='hourly-terms' element={<HourlyTerms />} />
        <Route path='goaloriented-terms' element={<GoalOriented />} />
        <Route path='termsofuse' element={<TermsOfUse />} />
        <Route path='privacypolicy' element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </main>
  )
}

export default App
