import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo";
import Header from "../sections/header/Header";
import { WorkflowSection } from "../ui/workflow-section/WorkflowSection";
const agile = new URL('../../assets/agile.png', import.meta.url);

export const HowWeWork = () => {

  const workflow = sections[3];

  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | How We Get Things Done</title>
        <meta name="description" content="Kraken Creative Studios | How We Get Things Done" />
      </Helmet>
      <Header section={workflow} />
      <div className="py-5 sm:pt-16">
        <div className="py-14 px-5 sm:px-56">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{workflow.content.deliver.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{workflow.content.deliver.text}</p>
        </div>

        <div className={`bg-hero-workflow bg-no-repeat bg-cover bg-center h-[181px] sm:h-[386px] w-full bg-fixed`}></div>

        <div className="py-14 px-5 sm:px-56">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{workflow.content.pipelines.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{workflow.content.pipelines.text}</p>
        </div>


        <WorkflowSection variant="concept" />
        <WorkflowSection variant="technical" />
        <WorkflowSection variant="game" />
        <WorkflowSection variant="graphic" />

        <div className="py-14 px-5 sm:px-56">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{workflow.content.agile.title}</h3>
        </div>

        <div className="sm:px-56 px-5 bg-backgroundblack">
          <div className="sm:py-8 flex flex-col sm:flex-row items-center sm:justify-between">
            <img src={agile} alt="We are agile!" aria-hidden="true" />
            <p className="text-white font-normal sm:font-semibold sm:w-[467px] py-5 sm:py-0">{workflow.content.deliver.text}</p>
          </div>
        </div>


      </div>
    </>
  )
}

