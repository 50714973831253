import { useState } from 'react';
import { social } from '../../../data/usefulUrls';
const close = new URL('../../../assets/vectors/akar-icons_cross.png', import.meta.url);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ContactForm = ({setShowModal, isModal}) => {

    const [message, showMessage] = useState(false);

    const handleToggle = () => {
        showMessage((current) => !current);
    };

    const onSubmit = async (event) => {
        // prevent redirect
        event.preventDefault();
        // setIsLoading(true);
    };

    return (
        <div className='flex flex-col sm:flex-row justify-center p-5'>
            <div className="isolate sm:px-6 sm:py-8 lg:px-8 sm:bg-graycontact w-full sm:w-4/6 sm:border-solid sm:border-turquoise sm:border-l-2 rounded-lg">
                <div className="mx-auto max-w-screen-md ">
                    <h2 className="text-2xl font-bold tracking-tight text-turquoise">Contact Form</h2>
                </div>
                <form onSubmit={onSubmit} action="mailto:hello@kcsoutsourcing.com" method="POST" className="mx-auto max-w-screen-md">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>

                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    placeholder='First Name'
                                    className="bg-backgroundgray block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>

                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    placeholder='Second Name'
                                    autoComplete="family-name"
                                    className="bg-backgroundgray block w-full rounded-md border-0 text-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="">

                            <div className="mt-2.5">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder='Email Address'
                                    autoComplete="email"
                                    className="bg-backgroundgray text-white block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="">

                            <div className="mt-2.5">
                                <input
                                    type="tel"
                                    name="phone-number"
                                    id="phone-number"
                                    autoComplete="tel"
                                    placeholder='Phone Number'
                                    className="bg-backgroundgray text-white block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                Your Message
                            </label>
                            <div className="mt-2.5">
                                <textarea
                                    name="message"
                                    id="message"
                                    rows={4}
                                    className="md:h-56 bg-backgroundgray block w-full rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 pb-5 sm:pb-0 flex flex-col sm:flex-row items-end sm:items-center justify-between">
                        <button
                            type="submit"
                            className="block rounded-md bg-krakenpurple px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border border-transparent hover:border-white"
                            onClick={handleToggle}
                        >
                            Send Us A Message
                        </button>
                        {message && <p className='text-turquoise text-sm'>Great! Your message is on its way, we will get in touch shortly!</p>}
                    </div>

                </form>
            </div>

            <div className='sm:bg-graycontact border-solid border-turquoise sm:border-l-2 rounded-lg m-0 sm:ml-3 w-full sm:w-28 flex sm:flex-col items-center justify-around '>
                {social.map(social => <a href={social.url} target="_blank" className="pl-2.5"><img src={social.image} className='w-7 sm:w-12' /></a>)}
            </div>
            {isModal && <button
                className="cursor-pointer flex background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
            >
                <img src={close} />
            </button> }
            
        </div>

    )
}
