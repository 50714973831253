const thumbnails = new URL('../assets/concept-cards/concept01.png', import.meta.url);
const sketch = new URL('../assets/concept-cards/concept02.png', import.meta.url);
const final = new URL('../assets/concept-cards/concept03.png', import.meta.url);
const modeling = new URL('../assets/concept-cards/concept04.png', import.meta.url);
const texturing = new URL('../assets/concept-cards/concept05.png', import.meta.url)
const rigging = new URL('../assets/concept-cards/concept06.png', import.meta.url);
const blocking = new URL('../assets/concept-cards/concept07.png', import.meta.url);
const spline = new URL('../assets/concept-cards/concept08.png', import.meta.url);
const implementation = new URL('../assets/concept-cards/concept09.png', import.meta.url);

export const conceptImages = [
    { title: 'Thumbnails', image: thumbnails },
    { title: 'Sketch', image: sketch },
    { title: 'Final Concept', image: final },
    { title: 'Modeling', image: modeling },
    { title: 'Texturing', image: texturing },
    { title: 'Polishing & Rigging', image: rigging },
    { title: 'Blocking', image: blocking },
    { title: 'Spline', image: spline },
    { title: 'Polish & Implementation', image: implementation },
]
