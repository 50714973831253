import { Link } from "react-router-dom";
import { sections } from "../../data/sectionsInfo";
import Header from "../sections/header/Header";
import Button from "../ui/button/Button";

export const PrivacyPolicy = ({ data }) => {

  const terms = sections[6];
  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | Privacy Policy</title>
        <meta name="description" content="Kraken Creative Studios | Privacy Policy" />
      </Helmet>
      <Header section={terms} />
      <div className="px-4 py-5 sm:px-56 sm:pt-16">
        <div className="py-14">
          <p className="text-white font-normal sm:font-semibold">{terms.content.main}</p>
          <ul className="mt-5">
            {terms.content.list.map(item => <li className="mt-2 text-white font-normal sm:font-semibold">{item}</li>)}
          </ul>
          <p className="mt-5 text-white font-normal sm:font-semibold">{terms.content.note}</p>
        </div>
        <Link to="/">
          <Button text={'Back to the Homepage'} variant={'bg-krakenpurple text-white font-semibold mt-12'} container={'text-right'} />
        </Link>
      </div>
    </>
  )
}
