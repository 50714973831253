import React from 'react'
import { ContactForm } from '../../ui/contact-form/ContactForm';

export const Contact = () => {
  return (
    <div className="h-full w-full bg-darkgray main-container">
        <h2 className="text-4xl text-turquoise font-semibold text-center py-10">Reach Out</h2>
        <ContactForm />
    </div>

  )
}
