import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo";
import Header from "../sections/header/Header";
import { LeadCard } from "../ui/lead-card/LeadCard";


export const About = ({ data }) => {

  const about = sections[1];
  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | Who We Are</title>
        <meta name="description" content="Kraken Creative Studios | Who We Are" />
      </Helmet>
      <Header section={about} />
      <div className="px-4 py-5 sm:px-56 sm:pt-16">
        {about.content.titles.map(title => <h2 className="text-xl sm:text-4xl text-white font-semibold pb-2"><span className="text-turquoise">{title.pre} </span>{title.text}</h2>)}
        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{about.content.leadership.title}</h3>
        </div>

        <div className="grid sm:grid-cols-2 gap-5">
          {data.map(card => {
            const cardData = card.fields;
            return (
              <LeadCard name={cardData.card} role={cardData.role} description={cardData.description} imgUrl={cardData.photo.fields.file.url} />
            )
          })}
        </div>

        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{about.content.about.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{about.content.about.text}</p>
        </div>
      </div>
    </>
  )
}
