import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo";
import { serviceImages } from "../../data/servicesImages";
import { termsInfo } from "../../data/termsInfo";
import Header from "../sections/header/Header"
import { ServiceDescriptionCard } from "../ui/service-description-card/ServiceDescriptionCard";
import { TermsCard } from "../ui/terms-card/TermsCard";

export const OurServices = () => {

  const services = sections[2];

  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | How We Can Help You</title>
        <meta name="description" content="Kraken Creative Studios | How We Can Help You" />
      </Helmet>
      <Header section={services} />
      <div className="px-4 py-5 sm:px-56 sm:pt-16">
        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{services.content.topTalent.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{services.content.topTalent.text}</p>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-32">
          {serviceImages.map(service => <ServiceDescriptionCard imageUrl={service.image} title={service.title} description={service.description} />)}
        </div>

        <div className="py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{services.content.terms.title}</h3>
          <p className="text-white font-normal sm:font-semibold">{services.content.terms.text}</p>
        </div>

        <div className="grid sm:grid-cols-2 gap-5">
          {termsInfo.map(terms => <TermsCard key={terms.variant} title={terms.title} variant={terms.variant} description={terms.description} list={terms.details} />)}
        </div>
      </div>
    </>
  )
}

