import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo";
import Header from "../sections/header/Header";
import Button from "../ui/button/Button";

export const GoalOriented = ({ data }) => {

  const terms = sections[7];
  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | Goal Oriented Terms of Work</title>
        <meta name="description" content="Kraken Creative Studios | Hourly Terms of Work" />
      </Helmet>
      <Header section={terms} />
      <div className="px-4 py-5 sm:px-56 sm:pt-16">
        <div className="py-14">
          <p className="text-white font-normal sm:font-semibold pb-5">{terms.content.main}</p>
          <ul className="text-white font-normal sm:font-semibold">
            {terms.content.list.map(item => <li className="pb-2">{item}</li>)}
          </ul>
          <p className="text-white font-normal sm:font-semibold pt-4">{terms.content.note}</p>
        </div>
        <a href="/">
          <Button text={'Back to the Homepage'} variant={'bg-krakenpurple text-white font-semibold mt-12'} container={'text-right'} />
        </a>
      </div>
    </>
  )
}
