export const ServiceCard = ({title, variant}) => {

const cardVariant = {
    character: 'bg-character-art-card',
    animation: 'bg-animation-card',
    concept: 'bg-concept-art-card',
    game: 'bg-game-design-card',
    graphic: 'bg-graphic-ui-card',
    tech: 'bg-tech-art-card'
  }

  return (
    <div className={`${cardVariant[variant]} bg-no-repeat bg-cover bg-center h-[119px] md:h-[218px] flex items-end `}>
        <h3 className="text-white text-[18px] md:text-[32px] font-medium py-2 md:py-1 px-3 md:p-5">{title}</h3>
    </div>
  )
}

