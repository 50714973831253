export const jobsInfo = [
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'UE5 Lighting Artist',
        description: 'Lorem ipsum dolor sit amet consectetur. A leo suspendisse gravida porttitor pellentesque quis. Elementum diam fringilla suscipit egestas tellus adipiscing in tristique in.',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
]