import './button.scss';

const Button = ({text, variant, container}) => {
    return (
      <div className={`${container} flex-row-reverse md:flex-row`}>
          <button className={`${variant} px-5 py-1`}>{text}</button>
      </div>
    )
  }
  
  export default Button;