
export const ServiceDescriptionCard = ({ imageUrl, title, description }) => {
    return (
        <div className="bg-graycontact w-full sm:w-56 md:h-[354px] flex flex-col mb-4 sm:mb-0">
            <img src={imageUrl} className="w-full sm:w-56" />
            <div className="text-center p-3">
                <h2 className="text-white font-semibold pb-3">{title}</h2>
                <p className="text-white text-xs py-3 border-y-2 border-turquoise">{description}</p>
            </div>
        </div>
    )
}
