const conceptualization = new URL('../assets/technical-art-cards/technical01.png', import.meta.url);
const development = new URL('../assets/technical-art-cards/technical02.png', import.meta.url);
const optimization = new URL('../assets/technical-art-cards/technical03.png', import.meta.url);


export const technicalImages = [
    { title: 'Conceptualization', image: conceptualization },
    { title: 'Development - Prototype', image: development },
    { title: 'Optimization+Integration', image: optimization },
]
