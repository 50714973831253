
export const WorkflowCard = ({ imageUrl, title }) => {
    return (
        <div className="bg-graycontact w-56 h-[182px] flex flex-col mb-4 sm:mb-0 rounded-lg mx-5 sm:mx-0">
            <img src={imageUrl} className="w-full sm:w-56 rounded-t-lg" />
            <div className="text-center pt-2.5 px-2.5">
                <h2 className="text-white font-semibold pb-2 border-b-2 border-turquoise">{title}</h2>
            </div>
        </div>
    )
}
