const artstation = new URL('../assets/social/artstation.png', import.meta.url);
const facebook = new URL('../assets/social/facebook.png', import.meta.url);
const instagram = new URL('../assets/social/instagram.png', import.meta.url);
const linkedin = new URL('../assets/social/linkedin.png', import.meta.url);
const threads = new URL('../assets/social/threads.png', import.meta.url);
const twitter = new URL('../assets/social/twitter.png', import.meta.url);
const youtube = new URL('../assets/social/youtube.png', import.meta.url);


export const social = [
    { name: 'instagram', image: instagram, url: 'https://www.instagram.com/kcsoutsourcing/' },
    { name: 'twitter', image: twitter, url: 'https://twitter.com/kcsoutsourcing' },
    { name: 'linkedin', image: linkedin, url: 'https://www.linkedin.com/company/81962351/admin/' },
    { name: 'facebook', image: facebook, url: 'https://www.facebook.com/kcsoutsourcing' },
    { name: 'artstation', image: artstation, url: 'https://www.artstation.com/krakencreativestudios' },
    { name: 'threads', image: threads, url: 'https://www.threads.net/@kcsoutsourcing/' },
    { name: 'youtube', image: youtube, url: 'https://www.youtube.com/@krakenCreativeStudios' },
]