import { companiesFirstLine, companiesSecondLine } from "../../../data/companyLogos";

export const Companies = () => {
    return (

        <div className="cards-container mx-5 md:m-0 flex flex-col items-center py-20 md:my-20">
            <h2 className="text-2xl md:text-4xl text-turquoise font-semibold text-center sm:mb-10">Companies Our Team Members Worked With</h2>
            <div className="text-white w-full mt-1 md:mt-0">
                <div class="slider">
                    <div class="slide-track">
                        {companiesFirstLine.map(company => {
                            return (
                                <div class="slide">
                                    <img src={company.image} height="100" width="auto" alt="" />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div class="slider">
                    <div class="slide-track slide-track-2">
                        {companiesSecondLine.map(company => {
                            return (
                                <div class="slide">
                                    <img src={company.image} height="100" width="auto" alt="" />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
