import React, { useEffect } from 'react'
import { conceptImages } from '../../../data/conceptImages'
import { sections } from '../../../data/sectionsInfo';
import { technicalImages } from '../../../data/technicalImages';
import { designImages } from '../../../data/designImages';
import { graphicImages } from '../../../data/graphicImages';
import { WorkflowCard } from '../workflow-card/WorkflowCard';
const arrow = new URL('../../../assets/vectors/arrow.png', import.meta.url);

export const WorkflowSection = ({ variant }) => {

    const workflow = sections[3];

    const sectionVariantTitle = {
        concept: workflow.content.concept.title,
        technical: workflow.content.technical.title,
        game: workflow.content.game.title,
        graphic: workflow.content.graphic.title,
    }

    const sectionVariantImages = {
        concept: conceptImages,
        technical: technicalImages,
        game: designImages,
        graphic: graphicImages
    }

    console.log(variant);



    return (
        <>
            <div className="py-14 sm:px-56 ">
                <h3 className="text-2xl font-semibold text-turquoise pb-2 px-5 sm:px-0">{sectionVariantTitle[variant]}</h3>
            </div>

            {variant === 'concept' ? <ConceptSection variant={variant} sectionVariantImages={sectionVariantImages} /> : (
                <div className="sm:px-56 bg-backgroundblack hidden sm:block">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-16 sm:px-32 sm:py-8">
                        {sectionVariantImages[variant] && sectionVariantImages[variant].map(service => <WorkflowCard imageUrl={service.image} title={service.title} />)}
                    </div>
                </div>
            )}

            <div className='sm:hidden'>
                <swiper-container slides-per-view="1.5" autoplay-disable-on-interaction="false">
                    {sectionVariantImages[variant] && sectionVariantImages[variant].map(service => <swiper-slide><WorkflowCard imageUrl={service.image} title={service.title} /></swiper-slide>)}
                </swiper-container>
            </div>

        </>
    )
}



export const ConceptSection = ({ variant, sectionVariantImages }) => {

    const firstLine = sectionVariantImages[variant].slice(0, 3);
    const secondLine = sectionVariantImages[variant].slice(3, 6);
    const thirdLine = sectionVariantImages[variant].slice(6, 9);

    return (
        <div className="sm:px-56 bg-backgroundblack hidden sm:block sm:py-8">
            <div className="sm:grid sm:grid-cols-3 sm:gap-16 sm:px-32">
                {firstLine.map(service => <WorkflowCard imageUrl={service.image} title={service.title} />)}
            </div>
            <Arrow />
            <div className="sm:grid sm:grid-cols-3 sm:gap-16 sm:px-32">
                {secondLine.map(service => <WorkflowCard imageUrl={service.image} title={service.title} />)}
            </div>
            <Arrow />
            <div className="sm:grid sm:grid-cols-3 sm:gap-16 sm:px-32">
                {thirdLine.map(service => <WorkflowCard imageUrl={service.image} title={service.title} />)}
            </div>
        </div>
    )
}

const Arrow = () => <div className='flex justify-center'>
    <img src={arrow} />
</div>
