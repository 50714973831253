import { Link } from "react-router-dom";
import { social } from "../../../data/usefulUrls";
import Button from "../../ui/button/Button";

const logo = new URL('../../../assets/logo-footer.png', import.meta.url);

export const Footer = () => {
    return (
        <div className="h-64 sm:h-32 bg-graycontact flex flex-col sm:flex-row justify-around sm:justify-between items-center px-10 mt-20 md:mt-32">
            <div className="md:w-1/3 copyright flex flex-col sm:flex-row">
                <img src={logo} className="pr-12 hidden sm:block" />
                <div className="text-white">
                    <div>
                        <p>2023 Kraken Creative Studios, Inc</p>
                    </div>
                    <div>
                        <Link to="/terms-of-use"> <p className="inline font-normal underline decoration-solid">Terms Of Use</p></Link>
                        <Link to="/privacy-policy"> <p className="inline font-normal underline decoration-solid pl-2.5">Privacy Policy</p></Link>
                    </div>
                </div>
            </div>
            <div className="md:w-1/3 flex flex-col items-center">
                <div className="flex">
                    {social.map(social => <a href={social.url} target="_blank" className="pl-2.5"><img src={social.image} className='w-5 h-5' /></a>)}
                </div>
                <p className="text-white pt-2">hello@kcsoutsourcing.com</p>
            </div>
            <div className="md:w-1/3 flex flex-col items-center md:items-end">
                <div className="relative mt-2 rounded-md shadow-sm pb-2.5">
                    <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-[318px] rounded-md border-0 bg-backgroundgray ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 sm:w-80 text-center md:text-left py-1.5 px-0 md:px-2.5"
                        placeholder="Your Email Address"
                    />
                </div>
                <Button text={'Join The Newsletter'} variant={'bg-transparent border border-solid border-turquoise text-white font-bold hover:underline'} />
            </div>
        </div>
    )
}
