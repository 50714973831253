import React from 'react'
import { OurServices } from '../sections/our-services/OurServices'
import { Companies } from '../sections/companies/Companies'
import { Contact } from '../sections/contact/Contact'
import Header from '../sections/header/Header'
import { sections } from '../../data/sectionsInfo'

export const Homepage = () => {
    return (
        <>
            <Header section={sections[0]}/>
            <OurServices />
            <Companies />
            <Contact />
        </>
    )
}
