
import Button from '../../ui/button/Button';

const Header = ({section}) => {

  const backgroundVariant = {
    homepage: 'bg-hero-homepage',
    about: 'bg-hero-about',
    services: 'bg-hero-services',
    workflow: 'bg-hero-workflow',
    join: 'bg-hero-join',
    blog: 'bg-hero-blog',
    goalOrientedTerms: 'bg-hero-hourly',
    hourlyTerms: 'bg-hero-hourly'
  }

  const heightVariant = {
    homepage: 'h-[732px]',
    about: 'h-[257px]',
    services: 'h-[386px]',
    workflow: 'h-[386px]',
    join: 'h-[386px]',
    blog: 'h-[386px]',
    hourlyTerms: 'h-[386px]',
    goalOrientedTerms: 'h-[386px]',
  }

  const isHome = section.variant === 'homepage';
  
  return (
    <>
      <section className={`${backgroundVariant[section.variant]} header bg-no-repeat bg-cover bg-[-300px] md:bg-center mt-[-64px]`}>
        <div className={`w-full ${heightVariant[section.variant]} text-white header-content main-container content-center flex flex-col ${isHome ? 'justify-center' : 'justify-end'}`}>
          <div className='md:w-1/2 px-5'>
            <h1 className='text-2xl md:text-4xl font-semibold pb-6'>{section.title}</h1>
            {section.description && <p className='font-bold pb-5'>{section.description}</p> }
            {section.buttonText && <Button text={section.buttonText} variant={'btn-primary font-semibold border border-transparent hover:border-white'} /> } 
          </div>
        </div>
      </section>
    </>
  )
}

export default Header
