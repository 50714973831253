export const termsInfo = [
    {
        title: 'Hourly',
        description: 'These are the standard terms of work that we usually operate by. These may vary depending on the contract, client and general agreement with the client. These terms are oriented towards hourly work, and would not apply to hourly contracts.',
        variant: 'hourly-terms',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
    {
        title: 'Goal Oriented',
        description: 'These are the standard terms of work that we usually operate by. These may vary depending on the contract, client and general agreement with the client. These terms are oriented towards goal oriented work, and would not apply to hourly contracts.',
        variant: 'goal-oriented',
        details: [{ pre: 'Start Date', text: 'ASAP' }, 
        { pre: 'Job Type', text: 'Freelancer' }, 
        { pre: 'Location', text: 'Remote' },
        { pre: 'Payment', text: 'Upon Agreement' }],
        buttonText: 'Read Mode',
    },
]