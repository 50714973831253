import Button from "../button/Button"

export const JobListingCard = ({title, description, startDate, jobType, location, payment, jobUrl}) => {
    return (
        <div className="bg-graycontact sm:w-56 h-[244px] sm:h-[354px] flex items-center rounded-lg sm:mx-0 p-2.5 sm:px-0">
            <div className="p-2.5 sm:w-56">
                <h2 className="text-white sm:text-center font-semibold text-sm sm:text-base border-b-2 border-turquoise pb-1.5 w-[206px] sm:w-full ">{title}</h2>
                <p className="text-white text-xs pt-2.5">{description}</p>
                <div className="flex flex-col sm:items-center sm:justify-between">
                    <ul className="flex flex-wrap sm:block list-disc text-white marker:text-lightpurple sm:w-52 sm:border-b-2 border-turquoise pt-5 pb-3.5 sm:pb-10 px-2.5 sm:pl-5">
                        <li className="pr-[19px] sm:pr-0"><p className="text-xs"><span className="font-bold">Start Date: </span> {startDate}</p></li>
                        <li className="pr-[19px] sm:pr-0"><p className="text-xs"><span className="font-bold">Job Type: </span> {jobType}</p></li>
                        <li className="pr-[19px] sm:pr-0"><p className="text-xs"><span className="font-bold">Location: </span> {location}</p></li>
                        <li className="pr-[19px] sm:pr-0"><p className="text-xs"><span className="font-bold">Payment: </span> {payment}</p></li>
                    </ul>
                    <span className="sm:hidden border-b-2 border-turquoise w-[206px]"></span>
                    <a target="_blank" href={jobUrl}>
                        <Button text={'Learn More'} variant={'bg-transparent border border-solid border-turquoise text-white font-bold relative top-3 hover:underline mb-4 md:mb-0 mt-2'} container={'text-center'} />
                    </a>
                </div>
            </div>
        </div>
    )
}