import { Link } from "react-router-dom"
import Button from "../button/Button"

export const TermsCard = ({ title, description, list, variant }) => {
    return (
        <div className="bg-graycontact sm:w-[467px] h-[394px] sm:h-[354px] flex items-center rounded-lg p-2.5 mx-[-25px] sm:mx-0">
            <div className="px-7">
                <h2 className="text-white font-semibold text-2xl w-60 border-b-2 border-turquoise pb-1.5">{title}</h2>
                <p className="text-white text-xs pt-2.5">{description}</p>
                <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                    <ul className="list-disc text-white marker:text-turquoise w-52 border-b-2 border-turquoise pt-5 pb-10 px-2.5">
                        {list.map(item => <li className="text-xs"><span className="font-bold">{item.pre}: </span> {item.text}</li>)}
                    </ul>
                    <Link to={`/${variant}`}>
                        <Button text={'Read More'} variant={'bg-krakenpurple text-white font-semibold relative top-3 border border-transparent hover:border-white'} container={'text-center'} />
                    </Link>

                </div>
            </div>
        </div>
    )
}
