import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import Button from '../../ui/button/Button';
import { ContactForm } from '../../ui/contact-form/ContactForm';
const logo = new URL('../../../assets/logo-navbar.png', import.meta.url);

const subMenuItems = [
  { name: 'About Us', url: '/ourteam' },
  { name: 'Our Services', url: '/ourservices' },
  { name: 'How We Work', url: '/workflow' },
]

const navigation = [
  { name: 'Home', url: '/' },
  { name: 'About Us', url: '/ourteam', items: subMenuItems },
  { name: 'Portfolio', url: 'https://www.artstation.com/krakencreativestudios', target: '_blank' },
  { name: 'Join The Team', url: '/jobs' },
  { name: 'Blog', url: '/blog' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  const [showModal, setShowModal] = useState(false);

  return (
    <Disclosure as="nav" className="bg-darkgray sm:bg-myowngray relative">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center md:justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none ">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex w-2/3 md:w-full md:justify-between items-center sm:items-stretch">
                <div className="flex flex-shrink-0 items-center ">
                  <Link to="/"><img src={logo} className="h-8 w-auto logo" alt="Kraken Creative Studios" /></Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => {
                      if (item.items) {
                        return (<SubMenu item={item} />)
                      }
                      return (
                        <Link
                          key={item.name}
                          href={item.href}
                          target={item.target}
                          className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-white hover:text-turquoise',
                            'rounded-md px-3 py-2 text-sm font-semibold'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          to={item.url}
                        >
                          {item.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
                <div className="hidden absolute inset-y-0 right-0 md:flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0" onClick={() => setShowModal(true)} >
                  <Button text={'Contact Us'} variant={'bg-krakenpurple text-white font-semibold border border-transparent hover:border-white '} />
                </div>
                <Modal showModal={showModal} setShowModal={setShowModal} />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden absolute bg-darkgray w-full h-screen">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => {
                if (item.items) {
                  return (<SubMenuMobile item={item} />)
                }
                return (
                  <Link to={item.url}>
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-turquoise',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}

                    </Disclosure.Button>
                  </Link>

                )
              })}
            </div>
            <a className='text-gray-300 hover:text-turquoise block rounded-md px-5 py-2 text-base font-medium'>Get in touch</a>

          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


const SubMenu = ({ item }) => {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex p-0">

          <a key={item.name}
            href={item.href}
            className={classNames(
              item.current ? 'bg-gray-900 text-white' : 'text-white hover:text-turquoise hover:text-white',
              'rounded-md px-3 py-2 text-sm font-semibold'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </a>


        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-[130px] absolute right-0 z-10 mt-5 pb-1 origin-top-right bg-myowngray py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <Link
                href="#"
                className={classNames(active ? 'text-turquoise' : 'text-white', 'block p-2.5 text-sm transition-all')}
                to={item.items[0].url}
              >
                {item.items[0].name}

              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                href="#"
                className={classNames(active ? 'text-turquoise' : 'text-white', 'block p-2.5 text-sm transition-all ')}
                to={item.items[1].url}
              >
                {item.items[1].name}
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                href="#"
                className={classNames(active ? 'text-turquoise' : 'text-white', 'block p-2.5 text-sm transition-all')}
                to={item.items[2].url}
              >
                {item.items[2].name}
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const SubMenuMobile = ({ item }) => {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex p-0">
          <a key={item.name}
            href={item.href}
            className={classNames(
              item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-turquoise block rounded-md p-0 text-base font-medium',
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </a>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="relative right-0 z-10 pb-1 origin-top-right py-1 ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <Link to={item.url}>
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-turquoise',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.items[0].name}
                </Disclosure.Button>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link to={item.items[1].url}>
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-turquoise',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.items[1].name}
                </Disclosure.Button>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link to={item.items[2].url}>
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-turquoise',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.items[2].name}
                </Disclosure.Button>
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}


export const Modal = ({ setShowModal, showModal }) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full my-6 mx-auto">
              <ContactForm setShowModal={setShowModal} isModal />
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
