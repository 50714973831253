import { ServiceCard } from "../../ui/service-card/ServiceCard";
import { IndividualService } from "./IndividualService";

export const OurServices = () => {
    return (
        <div className="h-full w-full bg-darkgray main-container">
            <div className="services flex flex-col md:flex-row justify-around py-9 md:py-16">
                <IndividualService title="Global Team of Professionals" variant="world"/>
                <IndividualService title="Multi-Discipline Services" variant="settings"/>
                <IndividualService title="Cost Effective Solutions" variant="savings"/>
            </div>
            <div className="cards-container mx-5 md:m-0 flex flex-col items-center md:my-20">
                <h2 className="text-2xl md:text-4xl text-turquoise font-semibold pb-4 sm:pb-6">Our Services</h2>
                <div className="w-full gap-2.5 grid md:gap-5 md:grid-cols-2">
                    <ServiceCard title="3D Art" variant="character" />
                    <ServiceCard title="Concept Art" variant="animation" />
                    <ServiceCard title="Technical Art" variant="concept" />
                    <ServiceCard title="Animation" variant="game" />
                    <ServiceCard title="Game Design" variant="graphic" />
                    <ServiceCard title="Graphic & UX/UI Design" variant="tech" />
                </div>
            </div>
        </div>
    )
}