import Button from "../button/Button"

const blogImageMobile = new URL('../../../assets/blogImageMobile.png', import.meta.url)

export const BlogCard = ({name, role, description, imgUrl}) => {
    return (
        <div className="bg-graycontact w-full sm:w-[588px] h-[302px] sm:h-[234px] flex flex-col sm:flex-row items-center">
            <img src={imgUrl} className="hidden sm:block"/>
            <img src={blogImageMobile} className="sm:hidden w-full"/>
            <div className="pt-2 sm:pt-0 sm:pl-5 w-[319px] sm:w-[262px]">
                <h2 className="text-white font-semibold">{name}</h2>
                <h3 className="text-turquoise">{role}</h3>
                <p className="text-white text-xs pt-2.5">{description}</p>
                <Button text={'Read More'} variant={'bg-transparent border border-solid border-turquoise text-white font-bold relative top-3 hover:underline'} container={'mt-3.5 sm:mt-0'} />
            </div>
        </div>
    )
}
