const art = new URL('../assets/service-description-cards/service01.png', import.meta.url);
const conceptArt = new URL('../assets/service-description-cards/service02.png', import.meta.url);
const technicalArt = new URL('../assets/service-description-cards/service03.png', import.meta.url);
const animation = new URL('../assets/service-description-cards/service04.png', import.meta.url);
const gameDesign = new URL('../assets/service-description-cards/service05.png', import.meta.url)
const graphicDesign = new URL('../assets/service-description-cards/service05.png', import.meta.url);

export const serviceImages = [
    { title: '3D Art', image: art, description: 'Our 3D artists are most certainly the highlights of our studio. With AAA quality, profound experience in the gaming space and the unique characteristic of being the best artists out there, we’re confident that we’re able to help bring your game to life.' },
    { title: 'Concept Art', image: conceptArt, description: 'With one of the best concept art teams in the outsourcing realm, we’re proud not only of our level of quality but of our overall speed. We specialize primarily in creatures and environments, but can work on everything clothes to weaponry, as well as characters.' },
    { title: 'Technical Art', image: technicalArt, description: 'Our technical artists can help you with everything from rigging, technical animation, pipeline development, tools development, procedural animation, VFX pipeline, asset and animation implementation, and even shader creation.' },
    { title: 'Animation', image: animation, description: 'The animation team at Kraken Creative Studios not only works with manual animations, we’re also equipped with a Motion Capture studio optimized for gameplay animation. We also offer storyboarding and a team of actors to choose from.' },
    { title: 'Game Design', image: gameDesign, description: 'From Systems Design to Combat Design, from Narrative Design to Level Design, we’ve got you covered with a team of experienced AAA game designers ready to take your vision and make a game out of it. So, what is your vision? Let’s get it on paper.' },
    { title: 'Graphic & UX/UI Design', image: graphicDesign, description: 'UX/UI Design? Check. Graphic Design? Check. Branding? Check. Packaging Design? Check. You got design needs, we got you covered. Our team has worked with some of the biggest brands in the world, we can take it on.' },
]
