import { Helmet } from "react-helmet";
import { sections } from "../../data/sectionsInfo"
import Header from "../sections/header/Header"
import { BlogCard } from "../ui/blog-card/BlogCard";
import Button from "../ui/button/Button";
import { LeadCard } from "../ui/lead-card/LeadCard";

export const Blog = ({ data }) => {

  const blog = sections[5];

  return (
    <>
      <Helmet>
        <title>Kraken Creative Studios | Blog</title>
        <meta name="description" content="Kraken Creative Studios | Blog" />
      </Helmet>
      <Header section={blog} />
      <div className="py-5 sm:px-[122px] sm:pt-16">
        <div className="px-4 sm:px-0 py-14">
          <h3 className="text-2xl font-semibold text-turquoise pb-2">{blog.content.latest.title}</h3>
        </div>

        {data ? <LatestArticles articles={data} /> : <NoPosts />}

      </div>
    </>
  )
}


const LatestArticles = ({ articles }) => {
  return (
    <div>
      <div className="grid sm:grid-cols-2 gap-5">
        {articles.map(article => {
          const post = article.fields;
          const imgUrl = article.fields.photo.fields.file.url;
          return (
            <BlogCard name={post.title} description={post.description} imgUrl={imgUrl} />
          )
        })}
      </div>
      <Button text={'See More'} variant={'bg-krakenpurple text-white font-semibold mt-12 border border-transparent hover:border-white'} container={'text-center'} />
    </div>
  )
}

const NoPosts = () => {
  return (
    <p className="text-simplegray text-4xl text-center">There are no blog posts. Check back later!</p>
  )
}
