const conceptualization = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const systems = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const narrative = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const sketching = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const prototyping = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const implementation = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const layouts = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const blockouts = new URL('../assets/concept-cards/concept09.png', import.meta.url);
const environment = new URL('../assets/concept-cards/concept09.png', import.meta.url);

export const designImages = [
    { title: 'Conceptualization', image: conceptualization },
    { title: 'Systems', image: systems },
    { title: 'Narrative', image: narrative },
    { title: 'Sketching', image: sketching },
    { title: 'prototyping', image: prototyping },
    { title: 'Implementation', image: implementation },
    { title: 'Layouts', image: layouts },
    { title: 'Blockouts', image: blockouts },
    { title: 'Environment Art', image: environment },
]
