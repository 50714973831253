const imageUrl = new URL('../../../assets/lead-cards/lead01.png', import.meta.url)

export const LeadCard = ({name, role, description, imgUrl}) => {
    return (
        <div className="bg-graycontact sm:w-[467px] flex items-center rounded-lg p-2.5">
            <img src={imgUrl} className="w-[105px] h-[105px]"/>
            <div className="pl-5">
                <h2 className="text-white font-semibold">{name}</h2>
                <h3 className="text-turquoise">{role}</h3>
                <p className="text-white text-xs pt-2.5">{description}</p>
            </div>
        </div>
    )
}
