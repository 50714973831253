const savings = new URL('../../../assets/vectors/savings.png', import.meta.url);
const settings = new URL('../../../assets/vectors/settings.png', import.meta.url);
const world = new URL('../../../assets/vectors/world.png', import.meta.url);

const serviceVariant = {
    savings: savings,
    settings: settings,
    world: world,
}

export const IndividualService = ({ title, variant }) => {
    return (
        <div className="flex md:flex-col items-center py-4 px-20 md:p-0">
            <img className="w-20 md:w-52" src={serviceVariant[variant]}/>
            <h3 className="text-white m-0 font-semibold pl-2.5 md:p-8">{title}</h3>
        </div>
    )
}
