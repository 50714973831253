const blogImage = new URL('../assets/blogImage.png', import.meta.url);
const blogImageMobile = new URL('../assets/blogImageMobile.png', import.meta.url);


export const articles = [
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
    {
        title: 'Writing a compelling protagonist.',
        description: 'Lorem ipsum dolor sit amet consectetur. Lacinia ac ullamcorper est massa neque. Nunc sed eu enim lectus cras lacus fusce sapien. Congue aliquam tortor vestibulum sagittis. Gravida tristique vitae ac ultricies euismod mas',
        imageUrl: blogImage,
        buttonText: 'Read Mode',
    },
]