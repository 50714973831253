const amazonprime = new URL('../assets/logos/companies/amazon-prime.png', import.meta.url);
const amazon = new URL('../assets/logos/companies/amazon.png', import.meta.url)
const google = new URL('../assets/logos/companies/google.png', import.meta.url)
const solana = new URL('../assets/logos/companies/solana-sol.png', import.meta.url);
const burgerking = new URL('../assets/logos/companies/burger-king.png', import.meta.url)
const cocacola = new URL('../assets/logos/companies/coca-cola.png', import.meta.url)
const coldstone = new URL('../assets/logos/companies/cold-stone.png', import.meta.url)
const daveandbusters = new URL('../assets/logos/companies/daveandbusters.png', import.meta.url)
const disney = new URL('../assets/logos/companies/disney.png', import.meta.url)
const electronicarts = new URL('../assets/logos/companies/electronic-arts.png', import.meta.url)
const gamefreak = new URL('../assets/logos/companies/game-freak.png', import.meta.url)
const jamba = new URL('../assets/logos/companies/jamba.png', import.meta.url)
const microsoft = new URL('../assets/logos/companies/microsoft.png', import.meta.url)
const linkedin = new URL('../assets/logos/companies/linkedin.png', import.meta.url)
const mrbeast = new URL('../assets/logos/companies/mrbeast.png', import.meta.url)
const netflix = new URL('../assets/logos/companies/netflix.png', import.meta.url)
const polygon = new URL('../assets/logos/companies/polygon.png', import.meta.url)
const squareenix = new URL('../assets/logos/companies/squareenix.png', import.meta.url)
const ubisoft = new URL('../assets/logos/companies/ubisoft.png', import.meta.url)
const verizon = new URL('../assets/logos/companies/verizon.png', import.meta.url)
const vicarious = new URL('../assets/logos/companies/vicarious.png', import.meta.url)
const sandbox = new URL('../assets/logos/companies/sandbox.png', import.meta.url)
const axe = new URL('../assets/logos/companies/axe.png', import.meta.url)
const twoK = new URL('../assets/logos/companies/2k.png', import.meta.url)
const warner = new URL('../assets/logos/companies/warner.png', import.meta.url)
const activision = new URL('../assets/logos/companies/activision.png', import.meta.url)


export const companiesFirstLine = [
    { title: 'netflix', image: netflix },
    { title: 'disney', image: disney },
    { title: 'verizon', image: verizon },
    { title: 'solana', image: solana },
    { title: 'polygon', image: polygon },
    { title: 'vicarious', image: vicarious },
    { title: 'sandbox', image: sandbox },
    { title: 'burgerking', image: burgerking },
    { title: 'coldstone', image: coldstone },
    { title: 'daveandbusters', image: daveandbusters },
    { title: 'cocacola', image: cocacola },
    { title: 'jamba', image: jamba },
    { title: 'mrbeast', image: mrbeast },
    { title: 'axe', image: axe },
    { title: 'netflix', image: netflix },
    { title: 'disney', image: disney },
    { title: 'verizon', image: verizon },
    { title: 'solana', image: solana },
    { title: 'polygon', image: polygon },
    { title: 'vicarious', image: vicarious },
    { title: 'sandbox', image: sandbox },
    { title: 'burgerking', image: burgerking },
    { title: 'coldstone', image: coldstone },
    { title: 'daveandbusters', image: daveandbusters },
    { title: 'cocacola', image: cocacola },
    { title: 'jamba', image: jamba },
    { title: 'mrbeast', image: mrbeast },
    { title: 'axe', image: axe },
]


export const companiesSecondLine = [
    { title: 'squareenix', image: squareenix },
    { title: 'electronicarts', image: electronicarts },
    { title: 'gamefreak', image: gamefreak },
    { title: 'ubisoft', image: ubisoft },
    { title: 'twoK', image: twoK },
    { title: 'warner', image: warner },
    { title: 'activision', image: activision },
    { title: 'google', image: google },
    { title: 'amazon', image: amazon },
    { title: 'amazonprime', image: amazonprime },
    { title: 'linkedin', image: linkedin },
    { title: 'microsoft', image: microsoft },
    { title: 'squareenix', image: squareenix },
    { title: 'electronicarts', image: electronicarts },
    { title: 'gamefreak', image: gamefreak },
    { title: 'ubisoft', image: ubisoft },
    { title: 'twoK', image: twoK },
    { title: 'warner', image: warner },
    { title: 'activision', image: activision },
    { title: 'google', image: google },
    { title: 'amazon', image: amazon },
    { title: 'amazonprime', image: amazonprime },
    { title: 'linkedin', image: linkedin },
    { title: 'microsoft', image: microsoft },
]



